<template>
  <div class="step-content">
    <div class="detail">
      <h3 class="detail-name">
        高合{{ carSeriesInfo.carSeriesCn }} {{ carType.carTypeCn }}
      </h3>
      <div class="detail-opts">{{ vehicleInfo.vehicleDesc }}</div>
      <div class="detail-price">
        RM
        {{
          (
            +vehicleInfo.showCarPrice +
            +vehicleInfo.showCarDeposit +
            +vehicleInfo.showIntentionMoney
          ).toFixed(2)
        }}
      </div>
    </div>
    <DynamicForm
      ref="form"
      v-model="model"
      :rules="rules"
      :fieldData="fieldData"
    />
  </div>
</template>
<script>
import DynamicForm from "_c/dynamicForm";

export default {
  components: { DynamicForm },
  props: {
    vehicleInfo: {
      type: Object,
      default: () => ({}),
    },
    carSeriesInfo: {
      type: Object,
      default: () => ({}),
    },
    carType: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {},
      rules: {},
    };
  },
  computed: {
    fieldData() {
      return [
        {
          data: [
            {
              label: "Campaign Discount",
              key: "campaign",
              props: { placeholder: "Please enter" },
            },
            {
              label: "Dealer Discount",
              key: "dealer",
              props: { placeholder: "Please enter" },
            },
          ],
        },
      ];
    },
  },
  watch: {
    model: {
      handler() {
        let discount = 0
        if (this.model.campaign) discount += +this.model.campaign
        if (this.model.dealer) discount += +this.model.dealer
        this.$emit('on-discount-change', discount)
      },
      deep: true
    }
  },
  methods: {
    async validate() {
      const data = this.$refs.form.validate();
      if (data) return this.model;
    },
  },
};
</script>
<style lang="less" scoped>
.step-content {
  padding-top: 16px;

  .detail {
    margin-bottom: 16px;
    padding: 26px 24px 33px;
    background-color: #fff;

    .detail-name {
      font-size: 32px;
      color: #333333;
      line-height: 72px;
    }

    .detail-opts {
      margin-top: 16px;
      font-size: 24px;
      color: #666;
    }

    .detail-price {
      margin-top: 8px;
      font-size: 24px;
      color: #5f5f6a;
    }
  }
}
</style>